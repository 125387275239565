<template>
  <div :class="$style.mainWrapper">
    <aw-spinner v-if="showSpinner && contentEndPointUrl" />
    <template v-else>
      <div :class="$style.separator" />
      <div aria-live="assertive">
        <aw-notification-item
          v-if="apiError"
          :notification="apiError"
          thin-content
          index="0"
        />
      </div>
      <div :class="$style.content">
        <div :class="$style.text">
          <template v-if="$slots.contentTop?.()?.[0]?.children?.length">
            <slot name="contentTop" />
          </template>
          <aw-cms-page v-if="contentEndPointUrl" :content-data="content" force-full-width />
          <template v-if="$slots.contentBottom?.()?.[0]?.children?.length">
            <slot name="contentBottom" />
          </template>
        </div>
      </div>
    </template>
    <div :class="$style.actionButtons">
      <slot name="actionButtons" />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwStaticModalContent',
    components: {
      AwCmsPage: defineAsyncComponent(() => import('~~/common/components/Page/Content/AwCmsPage')),
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
    },
    props: {
      contentEndPointUrl: {
        type: String,
        default: '',
      },
      mainButtonStyle: {
        type: String,
        default: 'primary-outlined',
      },
    },
    data () {
      return {
        showSpinner: true,
        apiError: false,
        content: {},
      };
    },
    async created () {
      if (this.contentEndPointUrl) {
        try {
          this.content = await this.$api.$get(this.contentEndPointUrl) || {};
        } catch (error) {
          let title = error;
          let subtitle = '';

          if (error.response?.data?.title) {
            title = error.response.data.title;
            subtitle = error.response.data.detail;
          }
          this.apiError = {
            type: 'error',
            manualClose: true,
            iconName: 'error-16',
            text: {
              title,
              subtitle,
            },
          };
        }
        this.showSpinner = false;
      }
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.mainWrapper {
  text-align: left;

  .content {
    text-align: center;

    [data-content-text] {
      text-align: center;
    }
  }

  .separator {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px dashed $color-dash-separator;
    grid-area: separator;

    @include tablet(min) {
      margin-bottom: 40px;
    }
  }

  .actionButtons {
    margin-top: 16px;
    text-align: center;
  }
}
</style>
