<template>
  <aw-modal
    :opened="isOpened"
    :background-blured="isBackgroundBlurred"
    :size="modalSize"
    :closable="isCloseable"
    :modal-title="confirmTitle"
    :modal-title-attrs="{class: [$style.modalTitle], style: confirmTitleStyle}"
    @close-modal="onClickXBtn"
  >
    <template #content>
      <div :class="$style.modalContent">
        <div :class="$style.separator" />
        <div v-if="confirmText" :class="$style.modalText" v-html="confirmText" />
        <component
          :is="confirmComponent"
          v-if="confirmComponent"
          :class="$style.modalText"
          @call-action="callAction"
        />
        <div :class="$style.separator" />
        <div
          :class="[
            $style.actionButtons,
            {
              [$style.actionButtonsHorizontal]: confirmButtonsDirection === 'horizontal',
              [$style.actionButtonsSizeBig]: modalSize === 'md',
            },
          ]"
        >
          <template v-if="modalButtons">
            <lv-button
              v-for="(button, key) in modalButtons"
              :key="key"
              :styling="key === 'ok' ? 'primary-dark' : 'secondary-dark'"
              shrink
              :class="$style.actionButton"
              v-bind="button?.attrs || {}"
              @click="(button?.attrs && 'onClick' in button.attrs)
                ? null
                : ((key in defaultFunctions)
                  ? defaultFunctions[key]($event)
                  : null
                )
              "
            >
              {{
                (
                  button.text ||
                  (
                    (key === 'ok' || key === 'cancel')
                      ? $awt(`aw.global.${key}`)
                      : '-'
                  )
                )
              }}
            </lv-button>
          </template>
          <template v-else>
            <lv-button
              type="button"
              v-bind="confirmOkButtonAttrs"
              :styling="confirmOkButtonStyle ? confirmOkButtonStyle : 'primary'"
              shrink
              :class="[$style.actionButton, {[$style.reverseButton]: confirmOkButtonReverse}]"
              @click="(confirmOkButtonAttrs && 'onClick' in confirmOkButtonAttrs) ? null : callAction($event)"
            >
              {{ confirmOkButton ? confirmOkButton : $awt('aw.global.ok') }}
            </lv-button>
            <lv-button
              v-if="confirmCancelButton || !isCloseable"
              type="button"
              v-bind="confirmCancelButtonAttrs"
              :styling="confirmCancelButtonStyle ? confirmCancelButtonStyle : 'primary-outlined'"
              shrink
              :class="[$style.actionButton]"
              @click="(confirmCancelButtonAttrs && 'onClick' in confirmCancelButtonAttrs) ? null : closeModal($event)"
            >
              {{ confirmCancelButton ? confirmCancelButton : $awt('aw.global.cancel') }}
            </lv-button>
          </template>
        </div>
        <lv-button
          v-if="showRegistrationFooter"
          v-bind="registrationFooterButtonAttributes"
          type="button"
          styling="reset"
          :class="['buttonReset', $style.registrationFooter]"
          @click="handleRegistrationClick"
        >
          <span v-html="$awt('aw.common.confirm_modal.registration_button')" />
        </lv-button>
      </div>
    </template>
  </aw-modal>
</template>

<script setup>
  import { useModalStore } from '~~/common/stores/modal';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwModal from '~~/common/components/Common/AwModal';

  const props = defineProps({
    uuid: {
      type: String,
      default: '',
    },
    genericModal: {
      type: Object,
      default: null,
    },
  });

  const modal = computed(() => {
    return props.genericModal?.item || useModalStore().modals.confirmModal;
  });
  const isQueueSafe = computed(() => Boolean(props.genericModal && typeof props.genericModal === 'object'));
  const isOpened = computed(() => modal.value?.show);
  const isCloseable = computed(() => Boolean(modal.value?.closable));
  const isBackgroundBlurred = computed(() => Boolean(modal.value?.backgroundBlured));
  const modalData = computed(() => modal.value?.data || {});
  const modalButtons = computed(() => modalData.value?.buttons);
  const onClickXBtn = computed(() => modalData.value?.onClickX || closeModal);
  const modalSize = computed(() => modalData.value.confirmModalSize || 'sm');
  const confirmTitle = computed(() => modalData.value.confirmTitle);
  const confirmTitleStyle = computed(() => modalData.value.confirmTitleStyle);
  const confirmText = computed(() => modalData.value.confirmText ? modalData.value.confirmText.replace(/\n/gi, '<br/>') : false);
  const confirmComponent = computed(() => modalData.value.confirmComponent || false);
  const confirmButtonsDirection = computed(() => modalData.value.confirmButtonsDirection || false);
  const confirmOkButton = computed(() => modalData.value.confirmOkButton);
  const confirmOkActionType = computed(() => modalData.value.confirmOkActionType);
  const confirmOkButtonAttrs = computed(() => modalData.value.confirmOkButtonAttrs || null);
  const confirmOkButtonStyle = computed(() => modalData.value.confirmOkButtonStyle);
  const confirmOkButtonReverse = computed(() => modalData.value.confirmOkButtonReverse);
  const confirmCancelButton = computed(() => modalData.value.confirmCancelButton);
  const confirmCancelActionType = computed(() => modalData.value.confirmCancelActionType);
  const confirmCancelButtonAttrs = computed(() => modalData.value.confirmCancelButtonAttrs || null);
  const confirmCancelButtonStyle = computed(() => modalData.value.confirmCancelButtonStyle);
  const showRegistrationFooter = computed(() => modalData.value.showRegistrationFooter);
  const registrationFooterButtonAttributes = computed(() => modalData.value.registrationFooterButtonAttributes || {});
  const registrationFooterCallback = computed(() => modalData.value.registrationFooterCallback);

  const defaultFunctions = computed(() => ({
    ok: callAction,
    cancel: closeModal,
  }));

  function callAction () {
    const modalStore = useModalStore();
    if (isQueueSafe.value) {
      modalStore.removeQueueSafeConfirmModal({ uuid: props.uuid });
    } else {
      modalStore.setConfirmOk(confirmOkActionType.value || true);
      modalStore.hideConfirmModal();
    }
  }

  function closeModal () {
    const modalStore = useModalStore();
    if (isQueueSafe.value) {
      modalStore.removeQueueSafeConfirmModal({ uuid: props.uuid });
    } else {
      modalStore.setConfirmCancel(confirmCancelActionType.value || true);
      modalStore.hideConfirmModal();
    }
  }

  function handleRegistrationClick () {
    if (registrationFooterCallback.value) {
      registrationFooterCallback.value();
    }
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.modalContent {
  margin-top: -20px;
  text-align: center;

  @include tablet(min) {
    margin-top: -40px;
  }

  .modalTitle {
    margin: 8px 0 48px !important;
    text-align: left;
  }

  .modalText {
    text-align: center;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    &:not(.actionButtonsHorizontal) {
      @include mobile(max) {
        flex-direction: column;
      }
    }

    &SizeBig {
      @include mobile(max) {
        padding: 20px 0;
      }
    }

    .actionButton {
      width: min-content;
      text-align: center;

      &.reverseButton {
        order: 1;
      }
    }
  }
}

.separator {
  margin: 20px 0;
  border-bottom: 1px dashed $color-dash-separator;
  @include tablet(min) {
    margin: 40px 0;
  }
}

.registrationFooter {
  margin-top: 16px;

  @include tablet(min) {
    display: block;
    margin: 20px auto -20px;
  }
}
</style>
