<template>
  <client-only>
    <Teleport v-if="opened" to="#awTeleportTargetModal">
      <focus-trap
        :active="isFocusTrapActive"
        :escape-deactivates="false"
        :click-outside-deactivates="false"
        :initial-focus="initialFocus"
        :fallback-focus="fallbackFocus"
      >
        <div
          ref="modalWrapper"
          :class="[$style.modalWrapper, {[$style.modalWrapperBlured]: backgroundBlured}, 'modalWrapper']"
          tabindex="-1"
          aria-modal="true"
          role="dialog"
          :aria-labelledby="idLabel"
          @click="handleClickWrapper"
        >
          <div :class="$style.modalScrollBox">
            <div :class="[$style.modal, $style[`size-${size}`], $style[styling]]" :style="{maxHeight: windowHeight + 'px'}">
              <div ref="header" :class="$style.modalHeadingWrapper">
                <lv-heading v-if="modalTitle" :class="$style.modalHeading" v-bind="titleAttributes">
                  <span v-html="modalTitle" />
                </lv-heading>
                <lv-button
                  v-if="closable"
                  styling="small-primary-dark-just-icon"
                  :class="[$style.modalClose, {[$style.modalCloseWhiteRounded]: whiteRoundedCloseButton}]"
                  type="button"
                  :aria-label="$awt('aw.common.modal.close')"
                  @click="close"
                >
                  <lv-icon v-bind="closeIconAttrs" style="vertical-align: unset;" />
                </lv-button>
              </div>
              <div ref="modalContent" :class="[$style.modalContent, ...modalContentClasses]">
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>
      </focus-trap>
    </Teleport>
  </client-only>
</template>

<script>
  import { FocusTrap } from 'focus-trap-vue';
  import Headroom from 'headroom.js';
  import { mapState } from 'pinia';
  import { nextTick } from 'vue';
  import ModalConfig from '~~/common/components/loginet-vue-shop/config/ModalConfig';
  import { LvButton, LvIcon, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { uuid4 } from '~~/common/utils';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwModal',
    components: {
      LvButton,
      LvIcon,
      LvHeading,
      FocusTrap,
    },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: undefined,
      },
      closable: {
        type: Boolean,
        default: true,
      },
      idLabel: {
        type: String,
        default: null,
      },
      whiteRoundedCloseButton: {
        type: Boolean,
        default: false,
      },
      backgroundBlured: {
        type: Boolean,
        default: false,
      },
      modalTitle: {
        type: String,
        default: undefined,
      },
      modalTitleAttrs: {
        type: Object,
        default: () => ({}),
      },
      styling: {
        type: String,
        default: null,
      },
      modalContentClasses: {
        type: Array,
        default: () => ([]),
      },
    },
    emits: ['close-modal'],
    data () {
      const config = {
        ...ModalConfig,
        ...this.$appConfig.ModalConfig,
      };
      return {
        windowHeight: typeof window === 'undefined' ? 0 : window.innerHeight,
        uuid: uuid4(),
        /* safariFocusTimeoutId retries with a delay because VO/iOS does
         * not change the screen reader focus. Tested with Safari13+iOS13
         */
        safariFocusTimeoutId: null,
        isFocusTrapActive: Boolean(this.opened),
        closeIconAttrs: config.closeIconAttrsVersion2,
        headerPinned: false,
        headerOnTop: false,
        headroom: null,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      titleAttributes () {
        return {
          version2: true,
          tag: 'h3',
          level: '3',
          ...this.modalTitleAttrs,
        };
      },

    },
    watch: {
      opened: {
        immediate: true,
        handler (newVal, oldVal) {
          const userInterfaceStore = useUserInterfaceStore();
          const [bNewVal, bOldVal] = [Boolean(newVal), Boolean(oldVal)];
          if (bNewVal !== bOldVal) {
            userInterfaceStore.setBackdropChildren({
              uuid: this.uuid,
              value: bNewVal,
            });
            clearTimeout(this.safariFocusTimeoutId);
            if (!bNewVal) {
              this.isFocusTrapActive = bNewVal;
              this.destroyHeadroom();
            }
            if (bNewVal) {
              nextTick(() => {
                if (import.meta.client) {
                  this.safariFocusTimeoutId = setTimeout(() => {
                    this.isFocusTrapActive = bNewVal;
                  }, 300);
                  this.setDocumentHeight();
                  this.initHeadroom();
                }
              });
            }
          }
          if (newVal && !oldVal) {
            window.addEventListener('keydown', this.handleEsc);
          } else if (!newVal && oldVal) {
            window.removeEventListener('keydown', this.handleEsc);
          }
        },
      },
    },
    mounted () {
      window.addEventListener('resize', this.setDocumentHeight);
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.setDocumentHeight);
      const userInterfaceStore = useUserInterfaceStore();
      userInterfaceStore.setBackdropChildren({
        uuid: this.uuid,
        value: false,
      });
      clearTimeout(this.safariFocusTimeoutId);
    },
    methods: {
      setDocumentHeight () {
        this.windowHeight = window.innerHeight;
      },
      initHeadroom () {
        setTimeout(() => {
          nextTick(() => {
            if (this.screenRange['mobile-max'] && this.$refs.header) {
              this.headroom = new Headroom(this.$refs.header, {
                offset: 10,
                tolerance: {
                  up: 2,
                  down: 2,
                },
                scroller: this.$refs.modalContent,
                classes: {
                  initial: this.$style.modalHeadingWrapper,
                  pinned: this.$style.modalHeadingWrapperPinned,
                  unpinned: this.$style.modalHeadingWrapperUnpinned,
                  top: this.$style.modalHeadingWrapperTop,
                  notTop: this.$style.modalHeadingWrapperNotTop,
                  bottom: this.$style.modalHeadingWrapperBottom,
                  notBottom: this.$style.modalHeadingWrapperNotBottom,
                  frozen: this.$style.modalHeadingWrapperFrozen,
                },
                onTop: () => {
                  this.headerOnTop = false;
                },
                onNotTop: () => {
                  this.headerOnTop = true;
                },
              });
              this.headroom.init();
            }
          });
        }, 256);
      },
      destroyHeadroom () {
        this.headroom?.destroy();
      },
      initialFocus () {
        return this.$refs.modalWrapper;
      },
      fallbackFocus () {
        return this.$refs.modalWrapper;
      },
      close () {
        if (this.closable) {
          this.$emit('close-modal');
        }
      },
      handleClickWrapper (event) {
        if (event.target.classList.contains('modalWrapper')) {
          this.close();
        }
      },
      handleEsc (event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
    },

  };
</script>

<style module lang="scss" rel="stylesheet/scss">

$aw-modal-box-shadow: none;
$aw-modal-wrapper-background: $color-black-40;
$aw-modal-close-color: $color-black;
$aw-modal-min-height: 64px;
$aw-modal-wrapper-full-screen-background: $color-white;

$aw-modal-close-padding: 16px;
$aw-modal-close-font-size: 16px;
$aw-modal-close-background: transparent;

$aw-modal-full-screen-box-shadow: none;
$aw-modal-full-screen-close-background: $color-black-075;
$aw-modal-full-screen-close-hover-color: $color-brand-primary;
$aw-modal-full-screen-close-hover-background: $color-black-15;
$aw-modal-full-screen-close-active-color: $color-brand-primary;

$aw-modal-tablet-gap: 24px;
$aw-modal-desktop-small-gap: 32px;
$aw-modal-desktop-medium-gap: 40px;

$aw-modal-tablet-gutter: 48px;
$aw-modal-desktop-small-gutter: 60px;
$aw-modal-desktop-medium-gutter: 96px;

$aw-modal-tablet-all-column-count: 8;
$aw-modal-desktop-small-all-column-count: 12;
$aw-modal-desktop-medium-all-column-count: 12;

$aw-modal-sm-tablet-used-column-count: 4;
$aw-modal-sm-desktop-small-used-column-count: 6;
$aw-modal-sm-desktop-medium-used-column-count: 6;

$aw-modal-md-tablet-used-column-count: 6;
$aw-modal-md-desktop-small-used-column-count: 8;
$aw-modal-md-desktop-medium-used-column-count: 8;

$aw-modal-lg-tablet-used-column-count: 8;
$aw-modal-lg-desktop-small-used-column-count: 10;
$aw-modal-lg-desktop-medium-used-column-count: 10;

@function calculateModalWidth($gutter, $gap, $all-column-count, $used-column-count) {
  @return calc((100% - 2 * #{$gutter} - #{$all-column-count - 1} * #{$gap}) / #{$all-column-count} * #{$used-column-count} + #{$used-column-count + 1} * #{$gap})
}

$aw-modal-sm-tablet-width: calculateModalWidth($aw-modal-tablet-gutter, $aw-modal-tablet-gap, $aw-modal-tablet-all-column-count, $aw-modal-sm-tablet-used-column-count);
$aw-modal-sm-desktop-small-width: calculateModalWidth($aw-modal-desktop-small-gutter, $aw-modal-desktop-small-gap, $aw-modal-desktop-small-all-column-count, $aw-modal-sm-desktop-small-used-column-count);
$aw-modal-sm-desktop-medium-width: calculateModalWidth($aw-modal-desktop-medium-gutter, $aw-modal-desktop-medium-gap, $aw-modal-desktop-medium-all-column-count, $aw-modal-sm-desktop-medium-used-column-count);
$aw-modal-sm-desktop-large-width: 928px;

$aw-modal-md-tablet-width: calculateModalWidth($aw-modal-tablet-gutter, $aw-modal-tablet-gap, $aw-modal-tablet-all-column-count, $aw-modal-md-tablet-used-column-count);
$aw-modal-md-desktop-small-width: calculateModalWidth($aw-modal-desktop-small-gutter, $aw-modal-desktop-small-gap, $aw-modal-desktop-small-all-column-count, $aw-modal-md-desktop-small-used-column-count);
$aw-modal-md-desktop-medium-width: calculateModalWidth($aw-modal-desktop-medium-gutter, $aw-modal-desktop-medium-gap, $aw-modal-desktop-medium-all-column-count, $aw-modal-md-desktop-medium-used-column-count);
$aw-modal-md-desktop-large-width: 1224px;

$aw-modal-lg-tablet-width: calculateModalWidth($aw-modal-tablet-gutter, $aw-modal-tablet-gap, $aw-modal-tablet-all-column-count, $aw-modal-lg-tablet-used-column-count);
$aw-modal-lg-desktop-small-width: calculateModalWidth($aw-modal-desktop-small-gutter, $aw-modal-desktop-small-gap, $aw-modal-desktop-small-all-column-count, $aw-modal-lg-desktop-small-used-column-count);
$aw-modal-lg-desktop-medium-width: calculateModalWidth($aw-modal-desktop-medium-gutter, $aw-modal-desktop-medium-gap, $aw-modal-desktop-medium-all-column-count, $aw-modal-lg-desktop-medium-used-column-count);
$aw-modal-lg-desktop-large-width: 1520px;

$aw-modal-content-mobile-padding: 0 16px 0 16px;
$aw-modal-content-tablet-padding: 0 24px 40px 24px;
$aw-modal-content-desktop-small-padding: 0 32px 40px 32px;
$aw-modal-content-desktop-medium-padding: 0 40px 40px;

$aw-modal-content-mobile-margin: 0 0 20px 0;
$aw-modal-content-tablet-margin: 0;

$aw-modal-heading-mobile-padding: 20px 16px 0;
$aw-modal-heading-tablet-padding: 40px 24px 0;
$aw-modal-heading-desktop-small-padding: 40px 32px 0;
$aw-modal-heading-desktop-medium-padding: 40px 40px 0;

$aw-default-config: (
  border-radius: 24px,
);

$aw-modal-valid-sizes: (
  sm, //  6 column (4 column in tablet)
  md, //  8 column (6 column in tablet)
  lg, // 10 column (8 column in tablet)
  full-screen,
);

$aw-modal-background-radius-config: (
  sm: $aw-default-config,
  md: $aw-default-config,
  lg: $aw-default-config,
  full-screen: $aw-default-config,
);

$aw-modal-widths: (
  sm: (
    tablet: $aw-modal-sm-tablet-width,
    desktop-small: $aw-modal-sm-desktop-small-width,
    desktop-medium:$aw-modal-sm-desktop-medium-width,
    desktop-large: $aw-modal-sm-desktop-large-width,
  ),
  md: (
    tablet: $aw-modal-md-tablet-width,
    desktop-small: $aw-modal-md-desktop-small-width,
    desktop-medium: $aw-modal-md-desktop-medium-width,
    desktop-large: $aw-modal-md-desktop-large-width,
  ),
  lg: (
    tablet: $aw-modal-lg-tablet-width,
    desktop-small: $aw-modal-lg-desktop-small-width,
    desktop-medium: $aw-modal-lg-desktop-medium-width,
    desktop-large: $aw-modal-lg-desktop-large-width,
  ),
  full-screen: (
    mobile-small: 100%,
  ),
);

$aw-modal-content-paddings: (
  mobile-small: $aw-modal-content-mobile-padding,
  tablet: $aw-modal-content-tablet-padding,
  desktop-small: $aw-modal-content-desktop-small-padding,
  desktop-medium:$aw-modal-content-desktop-medium-padding,
);

$aw-modal-content-margins: (
  mobile-small: $aw-modal-content-mobile-margin,
  tablet: $aw-modal-content-tablet-margin,
);

$aw-modal-heading-paddings: (
  mobile-small: $aw-modal-heading-mobile-padding,
  tablet: $aw-modal-heading-tablet-padding,
  desktop-small: $aw-modal-heading-desktop-small-padding,
  desktop-medium:$aw-modal-heading-desktop-medium-padding,
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    @if map-has-key($map, $key) {
      $map: map-get($map, $key);
    } @else {
      @return null;
    }
  }
  @return $map;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: $aw-modal-min-height;
  pointer-events: auto;
  background-color: $color-background-3;
  box-shadow: $aw-modal-box-shadow;

  &.filter {
    background-color: $color-background-2;
  }

  @include tablet(min) {
    height: unset;
    max-height: unset !important;
  }

  &Heading {
    padding: 8px 0;

    &Wrapper {

      display: grid;
      flex: 0 0 auto;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 16px;
      grid-template-columns: auto 32px;

      @include tablet(min) {
        margin-bottom: 40px;
      }

      @each $res, $padding in $aw-modal-heading-paddings {
        @if ($padding) {
          @if ($res != mobile-small) {
            @include media($res) {
              padding: $padding;
            }
          } @else {
            padding: $padding;
          }
        }
      }

      &Top,
      &Frozen,
      &Pinned,
      &Unpinned,
      &Bottom,
      &NotBottom {
        /*! These classes have to exist in order for the Headroom.js to work */
      }

      &NotTop {
        .modalHeading {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &Wrapper {
    position: fixed;
    z-index: 6001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: auto;
    background: $aw-modal-wrapper-background;

    &Blured {
      background: rgba(16, 16, 16, 0.8);
    }
  }

  &ScrollBox {
    position: relative;
    z-index: 1;
    display: flex;
    overflow: visible;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-top: 0;
    pointer-events: none;

    @include tablet(min) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  &Close {
    font-size: $aw-modal-close-font-size;
    z-index: 1;
    padding: $aw-modal-close-padding;
    cursor: pointer;
    color: $aw-modal-close-color;
    border: none;
    background: $aw-modal-close-background;

    &WhiteRounded {
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: 1px solid $color-silver-chalice;
      border-radius: 999px;
      background: $color-white;
    }
  }

  @each $modal-size in $aw-modal-valid-sizes {
    $modal-width: map-get($aw-modal-widths, $modal-size);

    &.size-#{$modal-size} {
      border-radius: 0;
      @include tablet(min) {
        border-radius: map-deep-get($aw-modal-background-radius-config, $modal-size, border-radius);
      }

      @each $res, $width in $modal-width {
        @if ($width) {
          @if ($res != mobile-small) {
            @include media($res) {
              max-width: $width;
            }
          } @else {
            max-width: $width;
          }
        }
      }

      .modalContent {
        @each $res, $padding in $aw-modal-content-paddings {
          @if ($padding) {
            @if ($res != mobile-small) {
              @include media($res) {
                padding: $padding;
              }
            } @else {
              padding: $padding;
            }
          }
        }

        @each $res, $margin in $aw-modal-content-margins {
          @if ($margin) {
            @if ($res != mobile-small) {
              @include media($res) {
                margin: $margin;
              }
            } @else {
              margin: $margin;
            }
          }
        }
        overflow: auto;

        flex: 1 1 auto;
        border-radius: inherit;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
          scrollbar-width: none;
        }

        @include tablet(min) {
          overflow: visible;
          flex: unset;
        }
      }
    }
  }

  &.size-full-screen {
    box-shadow: $aw-modal-full-screen-box-shadow;

    .modal {
      &Wrapper {
        background: $aw-modal-wrapper-full-screen-background;
      }

      &Close {
        position: fixed;
        top: 10px;
        right: 18px;
        transition: background $animation-speed-medium $animation-timing-function;
        border-radius: 100px;
        background: $aw-modal-full-screen-close-background;

        &:hover,
        &:focus {
          color: $aw-modal-full-screen-close-hover-color;
          background: $aw-modal-full-screen-close-hover-background;
        }

        &:active {
          color: $aw-modal-full-screen-close-active-color;
        }
      }
    }
  }
}
</style>
